<template>
  <q-input
    v-model="duration"
    :label="label"
    type="number"
    :rounded="$q.platform.is.mobile"
    :outlined="$q.platform.is.mobile"
    :hint="hint"
    @blur="e => $emit('blur', e)"
  />
</template>

<script>
export default {
  props: {
    value: {
      required: true
    },
    label: {
      required: true
    },
    hint: {
      type: String,
      required: false
    }
  },
  computed: {
    duration: {
      get () {
        return this.value ? (this.value / 60) / 60 : null
      },
      set (val) {
        this.$emit('input', val ? (val * 60) * 60 : null)
      }
    }
  }
}
</script>

<style>

</style>
